import { Component } from '@angular/core';
import { AnalyticsService } from './service/google-analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'petszel-owner';
  constructor(private analyticsService: AnalyticsService) {
  }
}
