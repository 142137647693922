import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './service/authentication-guard.service';
import { LayoutModule } from './layout/layout.module';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('../app/home/home.module').then((m) => m.HomeModule),
    data: { bodyClass: 'gradient' },
  },
  {
    path: '',
    loadChildren: () =>
        import('../app/layout/layout.module').then(
          (m) => m.LayoutModule, 
        ), 
        data: { bodyClass: 'gradient' },
      },
];

@NgModule({
  imports: [    
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' }),
],
  exports: [RouterModule],
  providers: [{
    provide: AuthGuardService,
  }]
})
export class AppRoutingModule {}