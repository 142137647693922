import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { appConfig } from '../../owner.config';
import { catchError, map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class ContentfulService {
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute
  ) {
    if (this.token) {
      this.headers = new HttpHeaders().set(
        'Authorization',
        `Bearer ${this.token}`
      );
    } else {
      this.headers = new HttpHeaders();
    }
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
  }

  public token = localStorage.getItem('token');
  public headers: HttpHeaders;

  getContentAnimals<T = any>(categories: string[]): Observable<T> {
    const petszelAnimalId = localStorage.getItem('petszelAnimalId');
    const url = `${appConfig.apiUrl}Owner/Content/Animals/${petszelAnimalId}/Summary?tagIds=${categories}&matchAllTags=true`;
    return this.http.get<T>(url, { headers: this.headers });
  }

  getContent<T = any>(
    id: string, // This can be a slugId or an entryId
    idType: 'slug' | 'entry' // Specify the type of the id
  ): Observable<{ id: string; read: boolean; data: T }> {
    const petszelAnimalId = localStorage.getItem('petszelAnimalId');
    if (!petszelAnimalId || !id) {
      return of<{ id: string; read: boolean; data: T }>();
    }

    let url = `${appConfig.apiUrl}Owner/Content/Animals/${petszelAnimalId}`;

    // Append the appropriate query parameter based on the id type
    url += idType === 'slug' ? `?slugId=${id}` : `?entryId=${id}`;

    return this.http.get<T>(url, { headers: this.headers }).pipe(
      catchError((error: any) => throwError(error)),
      map((data: T) => ({
        id: id,
        read: (data as any)?.read === true,
        data: data,
      }))
    );
  }

  getEntryById(id: string, petszelAnimalId: string, idType: 'slug' | 'entry'): Observable<any> {
    if (!id || !petszelAnimalId) {
      // Handle error if id or petszelAnimalId is null or undefined
      return throwError('ID not provided');
    }

    // Determine the query parameter based on the type of the ID
    const queryParam = idType === 'slug' ? `slugId=${id}` : `entryId=${id}`;
    const url = `${appConfig.apiUrl}Owner/Content/Animals/${petszelAnimalId}?${queryParam}`;

    return this.http.get(url, { headers: this.headers });
  }

  getCtaProduct(): Observable<any>{
    const url = `${appConfig.apiUrl}Owner/Content/ProductsAndServices`;
    return this.http.get(url);
  }

  getAppBasic(slugId: string, petszelAnimalId: string): Observable<any> {
    const url = `${appConfig.apiUrl}Owner/Content/AppBasicPage/slug`;
    return this.http.get<any>(url, { headers: this.headers, params: { slugId, petszelAnimalId } });
  }
}
